.uc-nav-mask {
  background-color: #eeeeee;
  position: absolute;
  width: 240px;
  height: 274px;
  margin-top: -1rem;
  margin-left: -1rem;
  z-index: -1; }

.uc-nav {
  background-color: #eeeeee; }

.categories {
  text-align: end; }
  .categories .category {
    line-height: 1.2; }
    .categories .category .category-link {
      font-family: 'Campaign', sans-serif;
      border: none;
      font-size: 12px;
      margin: 0.2rem 0 0.2rem 0;
      padding: 0;
      color: #424242;
      text-decoration: none; }
    .categories .category.is-current .uc-dot {
      background: #e1331b;
      -webkit-transition: background-color 1s cubic-bezier(0, 0, 0.2, 1);
      -moz-transition: background-color 1s cubic-bezier(0, 0, 0.2, 1);
      -o-transition: background-color 1s cubic-bezier(0, 0, 0.2, 1);
      transition: background-color 1s cubic-bezier(0, 0, 0.2, 1); }
    .categories .category .uc-dot {
      display: inline-block;
      margin-left: 0.5rem;
      margin-bottom: -0.1rem;
      width: 12px;
      height: 12px;
      border-radius: 150px;
      background: #dedede; }
  .categories .sub-category .category-link {
    font-family: 'Campaign', sans-serif;
    border: none;
    font-size: 10px;
    margin: 0.2rem 0 0.2rem 0;
    padding: 0;
    color: #424242;
    text-decoration: none; }
  .categories .sub-category.is-current .category-link {
    color: #e1331b; }
  .categories .sub-category.is-current .uc-line {
    background: #e1331b; }
  .categories .sub-category .uc-line {
    display: inline-block;
    margin-left: 0.5rem;
    margin-bottom: -0.1rem;
    margin-right: 0.3rem;
    width: 4px;
    height: 16px;
    background: #d2d2d2; }
