.navbar-burger {
  width: 32px !important;
  height: 32px !important;
  margin: 1rem;
  border: none;
  border-radius: 50px; }

.navbar-logo {
  padding: 1rem; }

.navbar {
  height: 64px;
  background-color: rgba(238, 238, 238, 0.95) !important; }

.navbar-menu {
  margin-top: 0px;
  background-color: rgba(238, 238, 238, 0.95) !important;
  box-shadow: none !important; }
  .navbar-menu .logo-text {
    line-height: 1; }
  .navbar-menu .navbar-end .logo-text {
    padding: 0 1rem 1rem 1rem;
    line-height: 1; }
  .navbar-menu .navbar-end a.navbar-item {
    padding: 0.75rem 1rem 0.75rem 1rem;
    font-family: Campaign, 'sans-serif';
    font-size: 14px; }
    .navbar-menu .navbar-end a.navbar-item:hover {
      background-color: rgba(204, 204, 204, 0.2);
      color: #e1331b; }
